// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"43c93fb0eca59e12018549db03d6ed4a7f8400be"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV || process.env.NODE_ENV;
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_SAMPLE_RATE = process.env.SENTRY_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE;
// const SENTRY_TRACE_ORIGIN = process.env.SENTRY_TRACE_ORIGIN || process.env.NEXT_PUBLIC_SENTRY_TRACE_ORIGIN;

Sentry.init({
  dsn: NODE_ENV !== "development" ? SENTRY_DSN : "",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: Number(SENTRY_SAMPLE_RATE) || 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: NODE_ENV === "development",

  // replaysOnErrorSampleRate: 1.0,

  // // This sets the sample rate to be 10%. You may want this to be 100% while
  // // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,

  environment: NODE_ENV,

  integrations: [
    Sentry.browserTracingIntegration(),
    // new Sentry.Replay({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
  tracePropagationTargets: ["localhost", /^\//, /api.simplify.jobs\/v[2-4]/],
  beforeSend(event) {
    // Modify or drop the event here
    if (event.exception && event.exception.values) {
      const message = event.exception.values[0].value;
      if (
        message &&
        (message.includes("ResizeObserver loop") ||
          message.includes("Can't find variable: _chromeNamespace") ||
          message.includes("attempted to hard navigate to the same URL") ||
          message.includes("Safari not supported") ||
          message.includes("The operation is insecure") ||
          message.includes("ethereum") ||
          message.includes("solana") ||
          message.includes("BetterJsPop") ||
          message.includes("WeakMap key") ||
          message.includes("Failed to load static props") ||
          message.includes("Attempt to use history.replaceState() more than 100 times") ||
          message.includes("This script should only be loaded in a browser extension") ||
          message.includes(
            "Error acquiring lock for user creation. Most likely another request is already identifying this user."
          ) ||
          message.includes("An error occurred during user creation") ||
          message.includes("Sabre Message Timed out"))
      ) {
        return null;
      }
      if (message && message.includes("Cannot read properties of undefined (reading 'id')")) {
        const frames = event.exception.values[0].stacktrace?.frames;
        if (frames) {
          for (let i = 0; i < frames.length; i += 1) {
            if (frames[i].filename && frames[i].filename?.includes("injectCoinbaseWalletDappProvider")) {
              return null;
            }
          }
        }
      }
    }
    return event;
  },
  denyUrls: ["injectCoinbaseWalletDappProvider"],
});
